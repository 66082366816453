<template>
  <v-row justify="center" class="mt-5 mb-5">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-text>
          <v-card-text>
            <h1 class="display-1">Create new Course</h1>
          </v-card-text>
          <v-text-field
            ref="name"
            v-model="name"
            :rules="[() => !!name || 'This field is required']"
            :error-messages="errorMessages"
            label="Course name"
            placeholder="Course name"
            required
          ></v-text-field>
          <v-textarea
            ref="description"
            v-model="description"
            label="Description"
            :rules="[() => !!description || 'This field is required']"
            required
          ></v-textarea>
          <v-text-field
            ref="author"
            v-model="author"
            :rules="[() => !!author || 'This field is required']"
            :error-messages="errorMessages"
            label="Author"
            placeholder="Author"
            required
          ></v-text-field>
          <v-text-field
            ref="image"
            v-model="image"
            :rules="[() => !!image || 'This field is required']"
            :error-messages="errorMessages"
            label="Image link"
            placeholder="Image link"
            required
          ></v-text-field>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip v-if="formHasErrors" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh form</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>
          <v-btn
            color="teal accent-4"
            class="mr-2 white--text"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Create',

  data: () => ({
    errorMessages: '',
    name: null,
    description: null,
    author: null,
    image: null,
    formHasErrors: false,
  }),

  computed: {
    form() {
      return {
        name: this.name,
        description: this.description,
        author: this.author,
        image: this.image,
      }
    },
  },

  watch: {
    name() {
      this.errorMessages = ''
    },
  },

  methods: {
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },
    submit() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true

        this.$refs[f].validate(true)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: auto;
}
.no-background-hover::before {
  background-color: transparent !important;
}

.register-btn {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;
}
.register-btn:hover {
  opacity: 1;
}
</style>
